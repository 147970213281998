/* Imports */

@import "../standards.less";

@import "../includes/header.less";

@import "../includes/footer.less";

/*  Grid Definitions  */

body{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
    "hero"
    "title"
    "contnt"
    "footer";
}

#mastHeader{
    grid-area:hero;
    align-self: baseline;
}

#hero{
    grid-area:hero;
}

body>h1{
    grid-area: title;
}

#content{
    grid-area: contnt;
}

#mastFooter{
    grid-area: footer;
}

/*  Grid Definitions  */

/* Styling */

body{
    background-size: 135% auto;
    background-repeat: no-repeat;
    background-position: center 60vh;
    background-image: url(/wp-content/uploads/2020/03/logo-watermark.png);
    background-attachment:initial;
}

#hero{
    position:relative;
    flex-flow:row wrap;
    display:grid;
        picture{
            display:block;
            img{
                
                max-height:60vh;
                @media screen and (min-width:1420px){
                    height:60vh;
                }

            }

        }

    figcaption{
        font-family:@global-fonts-upgrade;
        font-size:1.636363rem;
        color:@atlantic-yach-n-ship-blue;
        padding: 0 20px;
        margin-left:auto;
    }

}



body>h1{

    text-align:right;
    padding:20px 40px;
    padding-top:1.5rem;
    margin-bottom:0;
    padding-bottom:0;
    font-size:@global-main-title-size;
    color:@atlantic-yach-n-ship-blue;
    margin-top:0;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content: flex-end;
    text-transform:uppercase;
    letter-spacing:0.05em;
    line-height:2.181818rem;


    span{
        font-size:@global-gray-title-size;
        color:@atlantic-yach-n-ship-light-gray;
        letter-spacing:0.06em;
        line-height: 32px;
        padding-right:20px;
    }

}
.broker-card{
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows:auto auto auto;
    grid-template-areas: "image" "name" "title";
    margin-bottom:30px;
    position:relative;
    @media screen and (max-width: 700px){
        text-align:center;
    }

    a{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
    img{
        grid-area:image;
        max-width:100%;
        width:250px;
        height:250px;
        object-fit:cover;
        object-position:center;
        @media screen and (max-width: 700px){
            margin:auto;
        }
    }
    h2{
        grid-area:name;
        text-transform:uppercase;
        font-family:@global-fonts-upgrade;
        font-size:1.2rem;
        color:@atlantic-yach-n-ship-blue;
        margin:0;

    }
    h3{
        grid-area:title;
        margin:0;
    }
}
#content{

    .homepage-card{
        position:relative;
        picture{
            display:grid;
        }
        figure{
            margin:0;
            img{
                width:100%;
                object-fit: cover;
                object-position: center;
            }
            figcaption{
                font-size:1rem;
                padding-top:0;
                white-space: nowrap;
                font-family:@global-fonts-upgrade;
                color:@atlantic-yach-n-ship-blue;
                padding-bottom:5px;
            }
        }
        &>a{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
    .perfectH2(){
        font-family:@global-fonts-upgrade;
        font-size:1.636363rem;
        color:@atlantic-yach-n-ship-blue;
        width:100%;
        border-bottom:2px solid @atlantic-yach-n-ship-blue;
        letter-spacing:0.06rem;
        text-transform:uppercase;
        margin-top:0;
        @media screen and (max-width: 700px){
            font-size:1.2rem;
        }
    }
        
    display:grid;
    grid-template-columns: 1.1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
    "sidebar description";

    #sidebar{
        grid-area: sidebar;
        padding:0 20px;
        @media screen and (min-width:1450px){
            margin: -1.7rem auto;
        }
        .cognito .gray-n-blue-title {
            text-align: left;
            text-align: center !important;
            width: fit-content;
            margin: auto;
            span{
                text-align:center !important;
                margin-inline-start: 0% !important;
            }
        }
    }

    &>.content{
        grid-area:description;
        display:grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 4.68rem;
        grid-template-areas: "bio vcard" "search search";
        padding:0 40px;
        align-self: baseline;
        
        h2.perfect{
            .perfectH2();
        }

        .bio{
            grid-area:bio;
            padding-top:30px;
        }
        
        .vcard{
            grid-area:vcard;

            img{
                max-width:100%;
                width:100%;
                margin-top:2rem;
            }

            #broker-social{
                font-size:2rem;
                color:@atlantic-yach-n-ship-blue;
                padding:1rem 0;
                a{
                    text-decoration: none;
                    color:inherit;
                    text-align:center;
                    padding:0 6px;
                }
            }

            .contact{
                p{
                    font-size:1.1rem;
                    color:@atlantic-yach-n-ship-blue;
                    i{
                        padding-right:15px;
                        font-size:2rem;
                    }
                    a{
                        text-decoration: none;
                        color:inherit;
                        display: grid;
                        grid-template-columns: 0.4fr 3fr;
                        align-items: center;
                    }
                }
            }

        }
        
        .search{
            grid-area:search;
            

            .featured-results{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                margin-bottom:30px;
            }

        }
        
    }

}

#yachts-by-country{
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom:20px;
    list-style:none;
    margin:0;
    li{
        color:@atlantic-yach-n-ship-blue;
        a{
            color: inherit;
            text-decoration: none;
        }
    }
}

.vessel-card{
    padding:0;
}
/* Styling */



@import "./staff-detail-responsive.less";