@import (reference) "../standards.less";

@media screen and (max-width: 1300px){

    body>h1{
        flex-flow:column nowrap;
    }

}

@media screen and (max-width:1500px){

    #content>.content .vcard #broker-social a {
        padding: 0;
        margin: 10px;
        display: inline-block;
    }

}

@media screen and (max-width: 1470px){
    #content>.content .info .featured-results{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1300px){
    #content{
        #sidebar{
            padding:0 10px;
            .cognito{
                padding:0;
            }
        }
        &>.content{
            .search{
                grid-template-areas:
                "results results results results"
                "cta cta . .";
            }
        }
    }
}

@media screen and (max-width: 1024px){
    #content{
        grid-template-areas:
        "description description"
        "sidebar sidebar";
        &> .content{
            padding:20px;
        }
    }
    #content>.content .bio {
        grid-area: bio;
        padding-top: 0px; 
    }
}

@media screen and (max-width:815px){
    #content>.content{
        grid-template-areas:
        "bio bio"
        "vcard vcard"
        "search search";
    }
    #content>.content .vcard {
        grid-area: vcard;
        display: grid;
        grid-template-columns: .3fr 1fr;
        grid-template-areas:
            "image social"
            "image contact"
    }
    #content>.content .vcard img {
        max-width: 100%;
        width: 100%;
        margin-top: 0;
        grid-area: image;
    }

    #content>.content .vcard #broker-social {
        font-size: 2rem;
        color: #00214d;
        padding: 0 10px;
        grid-area: social;
    }
    #content>.content .vcard .contact{
        grid-area: contact;
        padding: 0 20px;
    }
    #content>.content .vcard .contact p{
        line-height:normal;
        i{
            font-size:1.3rem;
        }
    }
}

@media screen and (max-width: 700px){
    #content>.content .search .featured-results{
        grid-template-columns: 1fr;
    }
    .vessel-card{
        grid-template-areas:
        "picture picture"
        "info info";
    }
    .vessel-card .image img{
        height:auto !important;
        max-height:initial !important;
    }
}

@media screen and (max-width: 445px){
    figcaption h1{
        font-size:1.7rem;
    }
    
    #content>.content .info .featured-results, #content>.content #results{
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 425px){
    #content>.content .vcard {
        grid-template-areas:
            "image image"
            "social social"
            "contact contact";
    }
    #content>.content .vcard img{
        margin:0 auto;
        width:250px;
    }
    body{
        background-position: center 15vh;
    }
}

@media screen and (max-width: 410px){
    #content>.content .search {
        grid-template-areas:
            "results results results results"
            "cta cta cta .";
    }
}